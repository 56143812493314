// src/components/CollegeList.js
import React, { useEffect, useState } from 'react';
import { FaChevronLeft, FaChevronRight, FaTimes } from 'react-icons/fa';
import SearchBar from './SearchBar';
import Sidebar from './Sidebar';

const ITEMS_PER_PAGE = 10;
const MAX_PAGE_BUTTONS = 5;

function CollegeList() {
  const [colleges, setColleges] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedCollegeIndex, setSelectedCollegeIndex] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    location: '',
    rank: { min: 1, max: 100 },  // Rank filter with default values
  });

  useEffect(() => {
    // Fetch data from the JSON file
    fetch('/colleges.json')
      .then((response) => response.json())
      .then((data) => {
        const maxRank = Math.max(...data.map((college) => college.rank), 0);
        const minRank = Math.min(...data.map((college) => college.rank), 1);
        setFilters((prevFilters) => ({
          ...prevFilters,
          rank: { min: minRank, max: maxRank },
        }));
        setColleges(data);
      })
      .catch((error) => console.error('Error fetching data:', error));
  }, []);

  const handleFilter = (newFilters) => {
    setFilters((prevFilters) => ({ ...prevFilters, ...newFilters }));
    setCurrentPage(1); // Reset to the first page when applying new filters
  };

  // Apply filtering logic when colleges are loaded
  const filteredColleges = colleges.length > 0
    ? colleges.filter((college) =>
        college.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
        college.location.toLowerCase().includes(filters.location.toLowerCase()) &&
        college.rank >= filters.rank.min &&
        college.rank <= filters.rank.max
      )
    : [];

  const totalItems = filteredColleges.length;

  const indexOfLastItem = currentPage * ITEMS_PER_PAGE;
  const indexOfFirstItem = indexOfLastItem - ITEMS_PER_PAGE;
  const currentColleges = filteredColleges.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(totalItems / ITEMS_PER_PAGE);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    setSelectedCollegeIndex(null); // Close the details when changing pages
  };

  const openDetails = (index) => {
    const adjustedIndex = index + indexOfFirstItem; // Adjust index based on the current page
    setSelectedCollegeIndex(adjustedIndex);
  };

  const navigateCollege = (direction) => {
    if (selectedCollegeIndex !== null) {
      const newIndex = selectedCollegeIndex + direction;
      if (newIndex >= 0 && newIndex < totalItems) {
        setSelectedCollegeIndex(newIndex);
      }
    }
  };

  const handleSearch = (term) => {
    setSearchTerm(term);
    setCurrentPage(1); // Reset to the first page when initiating a new search
  };

  const getPageNumbers = () => {
    const pages = [];
    const startPage = Math.max(1, currentPage - Math.floor(MAX_PAGE_BUTTONS / 2));

    for (let i = startPage; i <= Math.min(totalPages, startPage + MAX_PAGE_BUTTONS - 1); i++) {
      pages.push(i);
    }

    return pages;
  };

  return (
    <div className="flex">
      {/* Sidebar */}
      <Sidebar onFilter={handleFilter} />

      <div className="w-3/4 p-4">
        <SearchBar onSearch={handleSearch} />

        <div className="grid grid-cols-1 gap-4">
          {currentColleges.map((college, index) => (
            <div
              key={index}
              className="bg-white p-4 mb-4 rounded shadow-md cursor-pointer"
              onClick={() => openDetails(index)}
            >
              <h2 className="text-xl font-bold">{college.name}</h2>
              <p>Location: {college.location}</p>
              <p>Rank: {college.rank}</p>
              {/* Add more college details */}
            </div>
          ))}
        </div>

        {/* Compact Pagination */}
        <div className="flex justify-center mt-4">
          <button
            onClick={() => paginate(Math.max(1, currentPage - 1))}
            className={`mx-1 px-3 py-1 bg-blue-500 text-white rounded ${
              currentPage === 1 ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FaChevronLeft />
          </button>

          {getPageNumbers().map((page, index) => (
            <button
              key={index}
              onClick={() => paginate(page)}
              className={`mx-1 px-3 py-1 bg-blue-500 text-white rounded ${
                currentPage === page ? 'bg-blue-700' : ''
              }`}
            >
              {page}
            </button>
          ))}

          <button
            onClick={() => paginate(Math.min(totalPages, currentPage + 1))}
            className={`mx-1 px-3 py-1 bg-blue-500 text-white rounded ${
              currentPage === totalPages ? 'opacity-50 cursor-not-allowed' : ''
            }`}
          >
            <FaChevronRight />
          </button>
        </div>

        {/* Pagination Info */}
        <div className="flex justify-center mt-2 text-gray-600">
          Page {currentPage} of {totalPages}
        </div>

        {/* College Details */}
        {selectedCollegeIndex !== null && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div className="bg-white p-4 rounded shadow-md w-3/4 relative">
              <button
                onClick={() => setSelectedCollegeIndex(null)}
                className="absolute top-0 right-0 p-2 text-gray-600 hover:text-red-500"
              >
                <FaTimes />
              </button>
              <h2 className="text-xl font-bold">{filteredColleges[selectedCollegeIndex].name}</h2>
              {Object.entries(filteredColleges[selectedCollegeIndex]).map(([key, value]) => {
  // Exclude certain keys
  if (["stars", "guide", "recommended"].includes(key)) {
    return null; // Skip this iteration
  }

  // Capitalize the first letter of the key
  const capitalizedKey = key.charAt(0).toUpperCase() + key.slice(1);
  
  return (
    <p key={key} className="mb-2">
      <strong>{capitalizedKey.replace(/_/g, ' ')}:</strong> {value}
    </p>
  );
})}


              <div className="flex justify-between mt-4">
                <button
                  onClick={() => navigateCollege(-1)}
                  className="px-3 py-1 bg-blue-500 text-white rounded"
                >
                  <FaChevronLeft />
                </button>
                <button
                  onClick={() => navigateCollege(1)}
                  className="px-3 py-1 bg-blue-500 text-white rounded"
                >
                  <FaChevronRight />
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default CollegeList;
