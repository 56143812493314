// CollegeCard.js
import React from 'react';
import { useState } from 'react';

const CollegeCard = ({ college, onToggleDetails }) => {
  return (
    <div className="border border-gray-300 rounded-lg p-4 mb-4 h-[180px] overflow-hidden bg-white cursor-pointer transition duration-300 ease-in-out transform hover:scale-105" onClick={() => onToggleDetails(college)}>
      <div className="flex justify-between items-center">
        <div>
          <h2 className="text-lg font-bold text-blue-600 mb-1">{college.name}</h2>
          <p className="text-sm text-gray-500">Region: {college.region}, {college.country}</p>
          <p className="text-sm text-gray-500">Rank: {college.rank}</p>
        </div>
      </div>
    </div>
  );
};

export default CollegeCard;