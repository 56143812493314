import React from 'react';

function Announcement() {
  return (
    <div className="bg-blue-600 px-4 py-3 text-white">
      <p className="text-center text-sm font-medium">
        Confused about Masters Abroad?{' '}
        <a href="https://calendly.com/unisop/30min" className="inline-block underline text-blue-200 hover:text-blue-100">
          Book a Free Session
        </a>
      </p>
    </div>
  );
}

export default Announcement;
