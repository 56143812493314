import React from 'react';

const ComingSoonPage = () => {
  return (
    <div className="bg-gradient-to-br from-purple-700 to-indigo-900 min-h-screen flex flex-col justify-center items-center">
      <div className="max-w-lg text-center">
        <h1 className="text-4xl md:text-6xl font-bold text-white mb-6">Coming Soon</h1>
        <p className="text-lg md:text-xl text-gray-200 mb-8">We're working hard to bring you an amazing experience.</p>
        <div className="flex justify-center mb-8">
          <div className="w-8 h-8 bg-white rounded-full mr-2 animate-bounce"></div>
          <div className="w-8 h-8 bg-white rounded-full mr-2 animate-bounce"></div>
          <div className="w-8 h-8 bg-white rounded-full animate-bounce"></div>
        </div>
        {/* <div className="flex flex-col md:flex-row items-center justify-center space-y-4 md:space-x-4">
          <input type="email" placeholder="Enter your email" className="bg-white rounded-full px-4 py-2 w-full md:w-auto text-sm md:text-base focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent" />
          <button className="bg-indigo-600 text-white rounded-full px-6 py-2 md:px-8 md:py-3 hover:bg-indigo-700 transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">Subscribe</button>
        </div> */}
        <p className="text-sm text-gray-200 mt-4">Stay tuned for updates</p>
      </div>
    </div>
  );
};

export default ComingSoonPage;
