import React from 'react';

const PartnershipSection = () => {
  return (
    <section className="text-gray-600 body-font bg-white">
      <div className="container px-4 sm:px-8 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="text-xl sm:text-2xl lg:text-3xl font-medium title-font mb-4 text-black">OUR PARTNERSHIP</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            UniSOP is proud to announce its strategic partnership with <strong>StuImpact</strong>, 
            combining our expertise to enhance your graduate school application journey.
          </p>
        </div>
        <div className="flex flex-wrap -m-4">
          <div className="p-4 lg:w-1/2 md:w-full">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-8 h-8" viewBox="0 0 24 24">
                  <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">About StuImpact</h2>
                <p className="leading-relaxed text-base">
                  StuImpact is a leading student-led organization dedicated to empowering students 
                  in their academic and professional pursuits. With a focus on mentorship and 
                  resource sharing, StuImpact has helped countless students achieve their goals.
                </p>
              </div>
            </div>
          </div>
          <div className="p-4 lg:w-1/2 md:w-full">
            <div className="flex border-2 rounded-lg border-gray-200 border-opacity-50 p-8 sm:flex-row flex-col">
              <div className="w-16 h-16 sm:mr-8 sm:mb-0 mb-4 inline-flex items-center justify-center rounded-full bg-indigo-100 text-indigo-500 flex-shrink-0">
                <svg fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" className="w-8 h-8" viewBox="0 0 24 24">
                  <path d="M20 21v-2a4 4 0 00-4-4H8a4 4 0 00-4 4v2"></path>
                  <circle cx="12" cy="7" r="4"></circle>
                </svg>
              </div>
              <div className="flex-grow">
                <h2 className="text-gray-900 text-lg title-font font-medium mb-3">Partnership Benefits</h2>
                <p className="leading-relaxed text-base">
                  Through this partnership, UniSOP users gain access to StuImpact's vast network of mentors, 
                  exclusive resources, and specialized workshops. This collaboration enhances our ability to 
                  provide comprehensive support throughout your graduate school application process.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="flex justify-center mt-8">
          <a
            href="#" 
            className="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
          >
            Learn More About Our Partnership
          </a>
        </div> */}
      </div>
    </section>
  );
};

export default PartnershipSection;