import React from 'react';
import { Route, Switch } from 'react-router-dom';
import HomePage from './pages/HomePage';
import CuratedColleges from './pages/CuratedColleges';
import data from './pages/curatedList.json';
import LandingPage from './pages/LandingPage';
import AboutUs from './components/landing/AboutUs';
import ComingSoonPage from './components/landing/ComingSoonPage';
import MentorsPage from './pages/MentorsPage';

function App() {
  return (
    <Switch>
      <Route exact path="/" component={LandingPage} />
      <Route path="/curated-colleges" component={CuratedColleges} />
      <Route path="/qs-ranking" component={HomePage} />
      <Route path="/about-us" component={AboutUs} />
      <Route path="/coming-soon" component={ComingSoonPage} />
      <Route path="/mentors" component={MentorsPage} /> 
    </Switch>
  );
}

export default App;
