import React from 'react';

const CollegeDetailsPopup = ({ college, onClose }) => {
  return (
    <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center">
      <div className="absolute top-0 left-0 w-full h-full bg-gray-800 bg-opacity-50"></div>
      <div className="relative bg-white rounded-lg p-6 min-w-[400px] max-w-md">
        <button className="absolute top-0 right-0 p-2" onClick={onClose}>
          <svg className="w-6 h-6 text-gray-600" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
        <h2 className="text-lg font-bold mb-4">{college.name}</h2>
        <div className="mb-4">
          <p><span className="font-bold">Region:</span> {college.region}</p>
          <p><span className="font-bold">Country:</span> {college.country}</p>
          <p><span className="font-bold">City:</span> {college.city}</p>
        </div>
        <div className="mb-4">
          <p><span className="font-bold">Rank:</span> {college.rank}</p>
          <p><span className="font-bold">International Students Ratio Score:</span> {college.international_students_ratio}%</p>
          <p><span className="font-bold">Faculty-Student Ratio Score:</span> {college.faculty_student_ratio}</p>
          <p><span className="font-bold">Citations per Faculty Score:</span> {college.citations_per_faculty}</p>
          <p><span className="font-bold">Academic Reputation Score:</span> {college.academic_reputation}</p>
          <p><span className="font-bold">Employer Reputation Score:</span> {college.employer_reputation}</p>
          <p><span className="font-bold">International Faculty Ratio Score:</span> {college.international_faculty_ratio}</p>
          <p><span className="font-bold">Overall Score:</span> {college.overall_score}</p>
        </div>
        <div className="mb-4">
          <p><span className="font-bold">Application Fees:</span> ${college.application_fees}</p>
          <p><span className="font-bold">Tuition Fees:</span> ${college.tuition_fees}</p>
          <p><span className="font-bold">Living Cost (Yearly):</span> ${college.living_cost}</p>
          <p><span className="font-bold">CGPA Criterion:</span> {college.cgpa_criterion}</p>
          <p><span className="font-bold">IELTS Requirement:</span> {college.ielts_min}</p>
          <p><span className="font-bold">TOEFL Requirement:</span> {college.toefl_min}</p>
          {/* <p><span className="font-bold">Potential Salary Post Graduation:</span> ${college.potential_salary_post_graduation}</p> */}
        </div>
        <div className="mb-4">
  <p>
    <span className="font-bold">Official Webpage:</span> 
    <a href={college.link_to_official_webpage} target="_blank" rel="noopener noreferrer">{college.link_to_official_webpage}</a>
  </p>
  {college.useful_links && college.useful_links.trim() !== '' && (
    <p>
      <span className="font-bold">Useful links:</span>
      {college.useful_links.split(' ').map((link, index) => (
        <a key={index} href={link} target="_blank" rel="noopener noreferrer"> [{index + 1}]</a>
      ))}
    </p>
  )}
</div>


        {/* <div>
          <p><span className="font-bold">Link to Official Webpage:</span> <a href={college.link_to_official_webpage} target="_blank" rel="noopener noreferrer">{college.link_to_official_webpage}</a></p>
          <p><span className="font-bold">Useful Links:</span></p>
          <ul>
            {Object.entries(college["Useful links"]).map(([key, value]) => (
              <li key={key}><a href={value} target="_blank" rel="noopener noreferrer">{key}</a></li>
            ))}
          </ul>
        </div> */}
      </div>
    </div>
  );
};

export default CollegeDetailsPopup;
