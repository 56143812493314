import React from 'react';

const Footer = () => {
  return (
    <footer style={{ backgroundColor: '#004c8c', width: '100%' }} className="text-white py-4 px-6">
      <p className="text-sm" style={{ color: '#ffffff' }}>&copy; 2024 Unisop Curated College Directory</p>
    </footer>
  );
};

export default Footer;
