import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import heroBackground from './herosection.jpg';

const HeroSection = () => {
  const [words, setWords] = useState(["SOP", "Profile", "Resume"]);
  const [index, setIndex] = useState(0);
  const [currentWord, setCurrentWord] = useState('');
  const [charIndex, setCharIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCharIndex((charIndex) => {
        if (charIndex < words[index].length) {
          setCurrentWord((prev) => prev + words[index][charIndex]);
        } else {
          setCurrentWord('');
          setIndex((index + 1) % words.length);
          setCharIndex(0);
        }
        return charIndex + 1;
      });
    }, 210);

    return () => clearInterval(interval);
  }, [index, words]);

  return (
    <section style={{ backgroundImage: `url(${heroBackground})`, backgroundSize: 'cover', backgroundPosition: 'center' }}>
      <div className="mx-auto max-w-screen-xl px-4 py-20 lg:flex lg:h-screen lg:items-center backdrop-blur-sm rounded-lg">
        <div className="mx-auto max-w-4xl text-center">
          <div className="bg-white rounded-lg p-8 sm:p-12 lg:p-16 shadow-xl">
            <h1 className="text-2xl sm:text-3xl md:text-4xl lg:text-5xl font-extrabold text-black leading-tight">
              Crafting Career Paths
              <strong className="block text-blue-600">
                One <span style={{ color: '#FF8C00' }}>{currentWord}</span> at a Time.
              </strong>
            </h1>

            <p className="mt-4 sm:text-lg lg:text-xl text-black">
              Tired of consultancies that charge you an exorbitant amount of money to get you into college? 
              Worry no more, UniSOP is your highly personalized, one stop destination to your dream college. 
            </p>

            <div className="mt-6 sm:mt-8 flex flex-col sm:flex-row sm:justify-center">
              <a
                href="https://calendly.com/unisop/30min"
                className="block rounded bg-orange-500 px-8 py-3 text-sm font-bold text-white shadow hover:bg-blue-700 focus:outline-none focus:ring active:bg-blue-500 mb-4 sm:mr-4 sm:mb-0"
              >
                Book A Free Session
              </a>

              {/* <Link
                className="block rounded px-8 py-3 text-sm font-bold text-orange-500 shadow hover:text-blue-700 focus:outline-none focus:ring active:text-blue-500"
                to="/curated-colleges"
              >
                Discover our curated college directory
              </Link> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
