// pages/landing.js
import React from "react";
import Header from "../components/landing/Header";
import HeroSection from "../components/landing/HeroSection";
import Footer from "../components/landing/Footer";
import Announcement from "../components/landing/Announcement";
import TestimonialsSection from "../components/landing/TestimonialsSection";
import TeamSection from "../components/landing/TeamSection";
import PartnershipSection from "../components/landing/PartnershipSection";
import FeatureSection from "../components/landing/FeatureSection";
import ScrollableCTA from "../components/landing/ScrollableCTA";
import ProductComponent from "../components/landing/ProductImage";

const LandingPage = () => {
  return (
    <div>
      <Header />
      <Announcement />
      <HeroSection />
      {/* <ProductComponent /> */}
      <ScrollableCTA />
      <FeatureSection />
      <TestimonialsSection />
      {/* <TeamSection /> */}
      <PartnershipSection />
      <Footer />
    </div>
  );
};

export default LandingPage;
