import React from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const FeatureSection = () => {
  return (
    <section className="bg-white">
      <div className="container px-6 py-10 mx-auto">
        <h1 className="text-2xl font-semibold text-black capitalize lg:text-3xl">
          explore our <br /> awesome Services
        </h1>

        <div className="mt-2">
          <span className="inline-block w-40 h-1 bg-blue-500 rounded-full"></span>
          <span className="inline-block w-3 h-1 ml-1 bg-blue-500 rounded-full"></span>
          <span className="inline-block w-1 h-1 ml-1 bg-blue-500 rounded-full"></span>
        </div>

        <div className="mt-8 xl:mt-12 lg:flex lg:items-center">
          <div className="grid w-full grid-cols-1 gap-8 lg:w-1/2 xl:gap-16 md:grid-cols-2">
            <a href="https://calendly.com/unisop/30min" className="hover:shadow-xl">
              <div className="bg-white rounded-lg p-6 h-full">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z" />
                  </svg>
                </span>
  
                <h1 className="text-xl font-semibold text-black capitalize">Profile Evaluation</h1>
  
                <p className="text-gray-500">
                  No matter what stage of your application you are at, our team will evaluate your profile and provide you with a personalized plan.
                </p>
              </div>
            </a>

            <Link to="/coming-soon" className="hover:shadow-xl">
              <div className="bg-white rounded-lg p-6 h-full">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9.879 16.121A3 3 0 100 11L11 14H9c0 .768.293 1.536.879 2.121z" />
                  </svg>
                </span>
  
                <h1 className="text-xl font-semibold text-black capitalize">GPA Convertor</h1>
  
                <p className="text-gray-500">
                  Confused about how to convert your GPA to a scale of 4 ? <br /> We've got you covered.
                </p>
                
              </div>
            </Link>

            <Link to="curated-colleges" className="hover:shadow-xl">
              <div className="bg-white rounded-lg p-6 h-full">
                <span className="inline-block p-3 text-blue-500 bg-blue-100 rounded-xl">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 4a2 2 0 114 0v1a1 1 0 001 1h3a1 1 0 011 1v3a1 1 0 01-1 1h-1a2 2 0 100 4h1a1 1 0 011 1v3a1 1 0 01-1 1h-3a1 1 0 01-1-1v-1a2 2 0 10-4 0v1a1 1 0 01-1 1H7a1 1 0 01-1-1v-3a1 1 0 00-1-1H4a2 2 0 110-4h1a1 1 0 001-1V7a1 1 0 011-1h3a1 1 0 001-1V4z" />
                  </svg>
                </span>
  
                <h1 className="text-xl font-semibold text-black capitalize">Browse College Directory</h1>
  
                <p className="text-gray-500">
                  Look through our comprehensive and curated directory of the best ranked colleges from all around the globe.
                </p>
              </div>
            </Link>

            <Link to="/mentors" className="hover:shadow-xl transform transition duration-500 hover:scale-105">
              <div className="bg-gradient-to-r from-blue-100 to-indigo-100 rounded-lg p-6 h-full border-2 border-blue-500">
                <span className="inline-block p-3 text-blue-500 bg-white rounded-xl">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z" />
                  </svg>
                </span>
  
                <h1 className="text-xl font-semibold text-black capitalize">Connect with Mentors</h1>
  
                <p className="text-gray-700">
                  NEW! Get personalized guidance from current students at top universities. Book a session with a mentor in your field of interest today!
                </p>
              </div>
            </Link>
          </div>

          <div className="hidden lg:flex lg:w-1/2 lg:justify-center">
            <img className="w-[28rem] h-[28rem] flex-shrink-0 object-cover xl:w-[34rem] xl:h-[34rem] rounded-full" src="https://images.unsplash.com/photo-1581291518857-4e27b48ff24e?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1470&q=80" alt="" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default FeatureSection;