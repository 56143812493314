// src/components/Sidebar.js
import React, { useState } from 'react';

const Sidebar = ({ onFilter }) => {
  const [locationFilter, setLocationFilter] = useState('');

  const handleLocationFilterChange = (event) => {
    setLocationFilter(event.target.value);
    onFilter({ location: event.target.value });
  };

  return (
    <div className="w-1/4 bg-gray-100 p-4">
      <h2 className="text-xl font-bold mb-4">Filter Options</h2>
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Filter by Location:</label>
        <input
          type="text"
          value={locationFilter}
          onChange={handleLocationFilterChange}
          placeholder="Enter location"
          className="mt-1 p-2 border border-gray-300 rounded w-full"
        />
      </div>
    </div>
  );
};

export default Sidebar;
