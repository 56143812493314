import React from 'react';
import testimonial1 from './sreeharshapic.jpeg';
import testimonial2 from './sushyanttestimonial.jpeg';

const TestimonialsSection = () => {
  return (
    <section className="text-gray-600 body-font bg-white">
      <div className="container px-4 sm:px-8 py-16 mx-auto">
        <div className="flex flex-col text-center w-full mb-10">
          <h1 className="text-3xl sm:text-4xl lg:text-5xl font-medium title-font mb-4 text-black">Hear from our customers</h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">Read through the amazing experiences our previous clients have had with us and their journey to being accepted into the college of their choice</p>
        </div>
        <div className="flex flex-wrap -m-4 justify-center">
          <div className="lg:w-1/3 md:w-1/2 p-4">
            <div className="h-full text-center">
              <img alt="testimonial" className="w-40 h-40 md:w-52 md:h-52 mb-8 object-cover object-center rounded-full inline-block border-4 border-gray-200 bg-gray-100" src={testimonial1} />
              <p className="leading-relaxed text-sm md:text-base mb-6">Joel and Yash have been pivotal in my journey of securing graduate admission in aerospace engineering. They were very efficient and skilled to underscore my unique capabilities with their commendable writing style. Their dedication and attention to detail while evaluating the SOPs were unmatched. Despite my seeking help from educational consultancies, most of which proved to be unworthy, UNISOP proved extremely beneficial. This fetched me admission to master's from top universities in aerospace engineering like the University of Colorado, Boulder, Arizona State University, and many more in the cards.</p>
              <h2 className="text-black font-medium title-font tracking-wider text-sm md:text-lg">Sai Sree Harsha Adavi</h2>
              <p className="text-gray-500 text-xs md:text-sm">University of Michigan, CU Boulder, Arizona State University</p>
            </div>
          </div>
          <div className="lg:w-1/3 md:w-1/2 p-4">
            <div className="h-full text-center">
              <img alt="testimonial" className="w-40 h-40 md:w-52 md:h-52 mb-8 object-cover object-center rounded-full inline-block border-4 border-gray-200 bg-gray-100" src={testimonial2} />
              <p className="leading-relaxed text-sm md:text-base mb-6">UNISOP really aided my application in the right direction. At their initial stages, they helped me narrow down my universities of interest, helped construct my application and informally explained to me what each university expects from its newly admitted students. Approaching a predominantly research-based institute, such as Virginia Tech, is an apt choice for my academic journey. And with gusto, I began my Master's program in Fall '23, in the Department of Industrial and Systems Engineering. Apart from providing me with a detailed outline of the set of tasks I was expected to carry out during the application process, Joel and Yash also assisted me in noting important aspects, such as documentation, grade reports, and language proficiency tests, as well as fundamental aspects such as avoiding application fees.</p>
              <h2 className="text-black font-medium title-font tracking-wider text-sm md:text-lg">Sushyant Sridhar</h2>
              <p className="text-gray-500 text-xs md:text-sm">Virginia Tech</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TestimonialsSection;
