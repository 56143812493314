// CTAComponent.js
import React from 'react';
import image from './heroback.jpg';

const CTAComponent = ({ isActive, description, profile }) => {
  return (
    <section className={`flex-shrink-0 w-full lg:w-screen bg-white lg:py-12 lg:flex lg:justify-center ${isActive ? 'block' : 'hidden'}`}>
      <div className="overflow-hidden bg-white lg:mx-8 lg:flex lg:max-w-6xl lg:w-full lg:shadow-md lg:rounded-xl">
        <div className="lg:w-1/2">
          <div className="h-64 bg-cover lg:h-full" style={{backgroundImage: `url(${image})`}}></div>
        </div>
        <div className="max-w-xl px-6 py-12 lg:max-w-5xl lg:w-1/2">
          <h2 className="text-2xl font-semibold text-black md:text-3xl">
            Build Your <span className="text-blue-600">{profile}</span>
          </h2>
          <p className="mt-4 text-black">{description}</p>
          <div className="inline-flex w-full mt-6 sm:w-auto">
            <a href="https://calendly.com/unisop/30min" className="inline-flex items-center justify-center w-full px-6 py-2 text-sm text-white duration-300 bg-blue-600 rounded-lg hover:bg-blue-700 focus:ring focus:ring-blue-300 focus:ring-opacity-80">
              Book A Free Session
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTAComponent;
