import React, { useState, useEffect } from 'react';
import Header from '../components/landing/Header';
import Sidebar from '../components/curatedColleges/SideBar';
import CollegeDirectory from '../components/curatedColleges/CollegeDirectory';
import Footer from '../components/curatedColleges/Footer';
import curatedColleges from './curatedList.json';
import SearchBar from '../components/curatedColleges/SearchBar';
import Pagination from '../components/curatedColleges/Pagination';

const CuratedColleges = () => {
  const [filteredColleges, setFilteredColleges] = useState(curatedColleges);
  const [searchQuery, setSearchQuery] = useState('');

  const handleSearch = ({ region, country, minRank, maxRank }) => {
    let filtered = curatedColleges;

    if (region) {
      filtered = filtered.filter(college =>
        college.region.toLowerCase().includes(region.toLowerCase())
      );
    }

    if (country) {
      filtered = filtered.filter(college =>
        college.country.toLowerCase().includes(country.toLowerCase())
      );
    }

    if (minRank) {
      filtered = filtered.filter(college =>
        college.rank >= minRank
      );
    }

    if (maxRank) {
      filtered = filtered.filter(college =>
        college.rank <= maxRank
      );
    }

    setFilteredColleges(filtered);
  };

  // Pagination functionality
  const [currentPage, setCurrentPage] = useState(1);
  const collegesPerPage = 15; // Number of colleges to display per page
  const indexOfLastCollege = currentPage * collegesPerPage;
  const indexOfFirstCollege = indexOfLastCollege - collegesPerPage;
  const currentColleges = filteredColleges.slice(indexOfFirstCollege, indexOfLastCollege);
  const totalPages = Math.ceil(filteredColleges.length / collegesPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  useEffect(() => {
    const search = searchQuery.toLowerCase();
    const filtered = curatedColleges.filter(college =>
      college.name.toLowerCase().includes(search)
    );
    setFilteredColleges(filtered);
  }, [searchQuery]);

  return (
    <div className="flex flex-col min-h-screen">
      <Header />
      <div className=" bg-gray-100 flex flex-1">
        <Sidebar onSearch={handleSearch} />
        <main className="flex-1 p-6">
          <SearchBar setSearchQuery={setSearchQuery} /> {/* Include the SearchBar component here */}
          <CollegeDirectory colleges={currentColleges} />
          <Pagination totalPages={totalPages} currentPage={currentPage} paginate={paginate} />
        </main>
      </div>
      <Footer />
    </div>
  );
};

export default CuratedColleges;
