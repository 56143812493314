import React from 'react';
import { Link } from 'react-router-dom';
import Logo from './Logo';

const Header = () => {
  const emailAddress = "unisophelp@gmail.com";

  return (
    <header className="bg-orange-100 py-4 shadow-md">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 flex justify-between items-center">
        <Link to="/" className="flex items-center">
          <Logo />
        </Link>
        <div className="text-sm text-gray-600">
          <span>Email: </span>
          <a href={`mailto:${emailAddress}`} className="underline">{emailAddress}</a>
        </div>
      </div>
    </header>
  );
};


const Footer = () => {
  return (
    <footer className="bg-gray-800 py-8">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex justify-center">
          <Link to="/" className="flex items-center">
            <svg
              className="h-8 w-8 text-white"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <circle cx="12" cy="12" r="10" />
              <line x1="12" y1="8" x2="12" y2="16" />
              <line x1="8" y1="12" x2="16" y2="12" />
            </svg>
            <span className="ml-2 text-xl font-bold text-white">Unisop</span>
          </Link>
        </div>
        <div className="mt-4 text-center text-gray-400">
          &copy; {new Date().getFullYear()} Unisop. All rights reserved.
        </div>
      </div>
    </footer>
  );
};

const AboutUs = () => {
  return (

    <div className="min-h-screen bg-gradient-to-t from-orange-500 to-amber-500">
      <Header />

      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-16">
        <div className="lg:text-center">
          <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-white sm:text-4xl">
            About UNISOP
          </p>
          <p className="mt-4 max-w-2xl text-xl text-gray-200 lg:mx-auto">
            Unisop is a comprehensive solution for students aiming to study at top global universities.
          </p>
        </div>

        <div className="mt-10">
          <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-white text-indigo-500">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z"
                    />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-white">One-on-One Sessions</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-200">
                We offer personalized one-on-one sessions to help you perfect your application materials, ensuring they stand out from the competition.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-white text-indigo-500">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 00-2-2h-2a2 2 0 00-2 2v14z"
                    />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-white">Advanced Tools</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-200">
                We provide advanced tools like a GPA converter and university predictor to help you make informed decisions throughout the admission process.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-white text-indigo-500">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M13 10V3L4 14h7v7l9-11h-7z"
                    />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-white">Simplified Process</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-200">
                Our goal is to simplify the complex admission process, making it easier for you to navigate and succeed.
              </dd>
            </div>

            <div className="relative">
              <dt>
                <div className="absolute flex items-center justify-center h-12 w-12 rounded-md bg-white text-indigo-500">
                  <svg
                    className="h-6 w-6"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z"
                    />
                  </svg>
                </div>
                <p className="ml-16 text-lg leading-6 font-medium text-white">Personalized Experience</p>
              </dt>
              <dd className="mt-2 ml-16 text-base text-gray-200">
                Our personalized approach ensures that you receive the guidance and support tailored to your unique needs and aspirations.
              </dd>
            </div>
          </dl>
        </div>

        <div className="mt-16 text-center">
          <p className="text-2xl font-bold text-white mb-4">Book a Free One-on-One Session</p>
          <a
            href="https://calendly.com/unisop/30min"
            target="_blank"
            rel="noopener noreferrer"
            className="inline-block bg-white text-indigo-600 font-semibold rounded-lg px-8 py-4 hover:bg-indigo-50 transition-colors duration-300"
          >
            Book Now
          </a>
        </div>
      </div>

      {/* <Footer /> */}
    </div>
  );
};

export default AboutUs;