import React, { useState, useEffect } from 'react';
import Header from '../components/landing/Header';
import Footer from '../components/curatedColleges/Footer';
import mentors from './mentors.json';

const MentorCard = ({ mentor, onOpen }) => {
  const educationParts = mentor.education.split('(Expected ');
  const degreeAndUniversity = educationParts[0].trim();
  const expectedYear = educationParts[1] ? `(Expected ${educationParts[1]}` : '';
  
  return (
    <div className="bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl">
      <div className="cursor-pointer" onClick={() => onOpen(mentor)}>
        <img src={mentor.image} alt={mentor.name} className="w-full h-48 object-cover" />
        <div className="p-4">
          <h3 className="text-xl font-semibold mb-2 text-gray-800">{mentor.name}</h3>
          <p className="text-gray-600 mb-2">{mentor.specialization}</p>
          <p className="text-sm text-gray-500">{degreeAndUniversity}</p>
          <p className="text-sm text-gray-500">{expectedYear}</p>
        </div>
      </div>
      <div className="border-t border-gray-200"></div>
      <div className="p-4 flex justify-between space-x-2">
        <button
          onClick={(e) => {
            e.stopPropagation();
            onOpen(mentor);
          }}
          className="bg-indigo-600 text-white px-4 py-2 rounded hover:bg-indigo-700 transition-colors flex-grow"
        >
          View Details
        </button>
        <a
          href={mentor.calendlyLink}
          target="_blank"
          rel="noopener noreferrer"
          onClick={(e) => e.stopPropagation()}
          className="bg-emerald-600 text-white px-4 py-2 rounded hover:bg-emerald-700 transition-colors flex-grow text-center"
        >
          Book Session
        </a>
      </div>
    </div>
  );
};

const MentorModal = ({ mentor, onClose }) => (
  <div
    className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50"
    onClick={onClose}
  >
    <div
      className="bg-white rounded-lg p-8 max-w-2xl w-full"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="flex justify-between items-start mb-4">
        <h2 className="text-3xl font-bold text-gray-800">{mentor.name}</h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12"></path>
          </svg>
        </button>
      </div>
      <div className="flex flex-col md:flex-row">
        <img src={mentor.image} alt={mentor.name} className="w-full md:w-1/3 h-auto rounded-lg mb-4 md:mb-0 md:mr-4" />
        <div>
          <p className="text-xl text-gray-600 mb-4">{mentor.specialization}</p>
          <p className="mb-4 text-gray-700">{mentor.bio}</p>
          <ul className="mb-4 text-gray-700">
            <li><strong>Experience:</strong> {mentor.experience}</li>
            <li><strong>Education:</strong> {mentor.education}</li>
            <li><strong>Languages:</strong> {mentor.languages.join(', ')}</li>
          </ul>
        </div>
      </div>
      <a
        href={mentor.calendlyLink}
        target="_blank"
        rel="noopener noreferrer"
        className="bg-emerald-600 text-white px-6 py-3 rounded block text-center hover:bg-emerald-700 transition-colors mt-4"
      >
        Book a Session
      </a>
    </div>
  </div>
);

const Pagination = ({ currentPage, totalPages, onPageChange }) => (
  <div className="flex justify-center mt-8">
    {[...Array(totalPages)].map((_, index) => (
      <button
        key={index}
        onClick={() => onPageChange(index + 1)}
        className={`mx-1 px-3 py-1 rounded transition-colors duration-300 ${
          currentPage === index + 1 ? 'bg-indigo-600 text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
        }`}
      >
        {index + 1}
      </button>
    ))}
  </div>
);

const MentorsPage = () => {
  const [selectedMentor, setSelectedMentor] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const mentorsPerPage = 9;
  const totalPages = Math.ceil(mentors.length / mentorsPerPage);

  const getCurrentMentors = () => {
    const startIndex = (currentPage - 1) * mentorsPerPage;
    const endIndex = startIndex + mentorsPerPage;
    return mentors.slice(startIndex, endIndex);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPage]);

  return (
    <div className="flex flex-col min-h-screen bg-gray-100">
      <Header />
      <main className="flex-grow container mx-auto px-4 py-12">
        <section className="mb-12 text-center">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">Choose Your Mentor</h1>
          <p className="text-xl text-gray-600 max-w-2xl mx-auto">
            Connect with experienced students from top US universities to guide you through your college journey.
          </p>
        </section>
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
          {getCurrentMentors().map((mentor) => (
            <MentorCard key={mentor.id} mentor={mentor} onOpen={setSelectedMentor} />
          ))}
        </div>
        <Pagination 
          currentPage={currentPage} 
          totalPages={totalPages} 
          onPageChange={setCurrentPage} 
        />
        {selectedMentor && (
          <MentorModal mentor={selectedMentor} onClose={() => setSelectedMentor(null)} />
        )}
      </main>
      <Footer />
    </div>
  );
};

export default MentorsPage;