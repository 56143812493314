import React, { useState } from 'react';
import Modal from 'react-modal';

Modal.setAppElement('#root'); // Required for accessibility

const Sidebar = ({ onSearch }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [countryQuery, setCountryQuery] = useState('');
  const [minRank, setMinRank] = useState('');
  const [maxRank, setMaxRank] = useState('');
  const [showModal, setShowModal] = useState(false); // State for modal visibility

  const handleSearch = () => {
    onSearch({
      region: searchQuery,
      country: countryQuery,
      minRank: minRank,
      maxRank: maxRank
    });
  };

  const handleCountryQueryChange = (value) => {
    let formattedValue = value.toLowerCase();
    // Case handling for synonyms
    if (formattedValue === "uk" || formattedValue === "britain" || formattedValue === "great britain") {
      return "United Kingdom";
    } else if (formattedValue === "us" || formattedValue === "usa") {
      return "United States";
    }
    return value;
  };

  const handleCountryQuery = (e) => {
    setCountryQuery(handleCountryQueryChange(e.target.value));
  };

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <aside className="bg-light-gray p-4 hidden md:block"> {/* Hide sidebar on mobile */}
      <h2 className="text-lg font-bold mb-4">Filters</h2>
      <input
        type="text"
        placeholder="Search by location..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        className="border border-gray-300 rounded-lg p-2 w-full mb-2"
      />
      <input
        type="text"
        placeholder="Search by country..."
        value={countryQuery}
        onChange={handleCountryQuery}
        className="border border-gray-300 rounded-lg p-2 w-full mb-2"
      />
      <div className="flex flex-col sm:flex-row mb-2">
        <input
          type="number"
          placeholder="Min Rank"
          value={minRank}
          onChange={(e) => setMinRank(e.target.value)}
          className="border border-gray-300 rounded-lg p-2 w-full sm:mr-2 mb-2 sm:mb-0"
        />
        <input
          type="number"
          placeholder="Max Rank"
          value={maxRank}
          onChange={(e) => setMaxRank(e.target.value)}
          className="border border-gray-300 rounded-lg p-2 w-full"
        />
      </div>
      <div className="flex justify-between">
        <button onClick={handleSearch} className="bg-blue-500 text-white rounded-lg px-4 py-2 w-full mb-2">
          Apply Filters
        </button>
        <button onClick={handleShowModal} className="bg-gray-500 text-white rounded-lg px-4 py-2 w-full mb-2 ml-2">
          Disclaimer
        </button>
      </div>
      <div className="border border-gray-300 rounded-lg p-4 mt-4">
        <span className="mr-4 text-gray-600">NA - Not Accepted</span>
        <span className="text-gray-600">NS - Not Specified</span>
      </div>
      <Modal
        isOpen={showModal}
        onRequestClose={handleCloseModal}
        contentLabel="Disclaimer Modal"
        style={{
          overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)'
          },
          content: {
            maxWidth: '90%',
            margin: 'auto',
            padding: '20px',
            borderRadius: '8px'
          }
        }}
      >
        {/* Disclaimer content */}
        <div className="text-center mb-6">
          <h2 className="text-xl font-bold">Disclaimer</h2>
        </div>
        <div className="mb-4">
          <ul className="list-disc pl-4">
            <li className="mb-2">
              Living cost expenses are approximate and/or average numbers for a single person living in the concerned city. Many college websites provide detailed information regarding living expenses, please refer to them for more information. We have also attached some quick links from the college website in the useful links section to help you get a quick glance at approximate costs.
            </li>
            <li className="mb-2">
              A large number of colleges require that either the TOEFL or IELTS standardized tests be taken. Some colleges accept Duolingo tests and the Pearson PTE test as well. Please check the official college website for detailed information. Also keep in mind that the requirements listed in this table are the correct to the best of our knowledge but can differ depending on department and course of study. Another piece of information that might be useful here is that many colleges also have specific requirements for different parts of the IELTS/TOEFL test. It is always a good idea to check the official website for more information.
            </li>
            {/* <li className="mb-2">
              Please note that these numbers are based on market research. Contributing factors to salary are field & nature of work, level of education attained, country of work and various other economic factors. We do not guarantee these numbers nor do we expect you to take this data at face value. A good way to read this information is to use it as a comparison with other colleges.
            </li> */}
            <li className="mb-2">
              Application fee amounts are subject to currency exchange rates, the amount mentioned is a converted and approximated in USD in Feb 2024. The information given here is pertaining to Engineering schools and can vary on the school of study that you apply to. In some cases, application fees can also change depending on whether you are an international student or not. These can be waived depending upon financial constraints and other eligibility criteria.
            </li>
            <li>
              CGPA Criterion can depend on course of study and various other factors. We would again strongly suggest you to look through the college website for more information.
            </li>
          </ul>
        </div>

        <div className="text-center">
          <button
            onClick={handleCloseModal}
            className="bg-blue-500 text-white rounded-lg px-4 py-2"
          >
            Dismiss
          </button>
        </div>
      </Modal>
    </aside>
  );
};

export default Sidebar;
