// CollegeDirectory.js
import React, { useState } from 'react';
import CollegeCard from './CollegeCard';
import CollegeDetailsPopup from './CollegeDetailsPopup';

const CollegeDirectory = ({ colleges }) => {
  const [selectedCollege, setSelectedCollege] = useState(null);

  const toggleDetails = (college) => {
    setSelectedCollege(college);
  };

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 flex-1 p-6 overflow-y-auto max-h-[calc(100vh-250px)] custom-scrollbar">
      {colleges.map((college, index) => (
        <CollegeCard key={index} college={college} onToggleDetails={toggleDetails} />
      ))}
      {selectedCollege && <CollegeDetailsPopup college={selectedCollege} onClose={() => setSelectedCollege(null)} />}
    </div>
  );
};

export default CollegeDirectory;
