import React from 'react';
import logo from './unisoplogo.png';

const Logo = () => {
  return (
    <div className="flex items-center justify-center">
      <img
        className="w-7 h-7 mr-2"
        src={logo}
        alt="Unisop Logo"
      />
      <span className="text-xl font-bold">
      <span className="text-red-500" style={{ color: '#ff0000' }}>UNI</span>
<span className="text-blue-800" style={{ color: '#006699' }}>SOP</span>

</span>
    </div>
  );
};

export default Logo;
