// src/components/SearchBar.js
import React, { useState } from 'react';

const SearchBar = ({ onSearch }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleInputChange = (event) => {
    const term = event.target.value;
    setSearchTerm(term);
    onSearch(term); // Trigger search on every input change
  };

  return (
    <div className="mb-4">
      <input
        type="text"
        placeholder="Search by college name..."
        value={searchTerm}
        onChange={handleInputChange}
        className="p-2 border border-gray-300 rounded w-full" // Set width to 100%
      />
    </div>
  );
};

export default SearchBar;
