import React, { useState, useEffect } from 'react';
import CTAComponent from './CTAComponent';

const ScrollableCTA = () => {
  const [currentComponent, setCurrentComponent] = useState(0);
  const totalComponents = 3;

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentComponent((prev) => (prev === totalComponents - 1 ? 0 : prev + 1));
    }, 5000); // Timer set for 5 seconds

    return () => clearInterval(timer);
  }, [totalComponents]);

  const descriptions = [
    "Unlock the gateway to your dream college with our tailored mentorship and round-the-clock support!  Have your academic history, extracurricular activities, and career aspirations analyzed to build a personalized roadmap to secure your spot at the college of your dreams.",
    "Unlock your success with the crucial SOP in college applications. It lets you share your journey, persuade institutions, showcase skills, stand out, address shortcomings, and boost scholarship chances. Join our mentorship for tailored guidance in crafting an SOP that reflects your aspirations. Let's shape your future together! ",
    "Your ticket to college success hinges on your resume – it's your snapshot of skills, qualifications, and passions. Don't allow a lackluster resume to diminish your prospects! Enroll in our personalized mentorship program in resume crafting to distinguish yourself and procure impressive templates honed to your profile. Let's make your application shine!"
  ];

  const profiles = [
    "Profile",
    "SOP",
    "Resume"
  ];

  return (
    <div className="relative">
      <div className="flex p-4">
        {Array.from({ length: totalComponents }).map((_, index) => (
          <div key={index} className={`flex-shrink-0 w-full ${index === currentComponent ? 'block' : 'hidden'}`}>
            <CTAComponent
              isActive={currentComponent === index}
              description={descriptions[index]}
              profile={profiles[index]}
            />
          </div>
        ))}
      </div>
      <div className="absolute inset-x-0 bottom-2 flex justify-center">
        {[...Array(totalComponents)].map((_, index) => (
          <button
            key={index}
            onClick={() => setCurrentComponent(index)}
            className={`w-2 h-2 mx-1 rounded-full ${index === currentComponent ? 'bg-blue-600' : 'bg-gray-300'}`}
          />
        ))}
      </div>
    </div>
  );
};

export default ScrollableCTA;
